<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="page-banner-content">
            <h2>Portfolio Two Column</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Portfolio Two Column</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Portfolio -->
<section class="portfolio-area pt-100 pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-9.jpg" alt="image">
                    </div>

                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Creative Web Design</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-2.jpg" alt="image">
                    </div>

                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Web Development</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-3.jpg" alt="image">
                    </div>

                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Web Application</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-4.jpg" alt="image">
                    </div>

                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Dashboard App</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-5.jpg" alt="image">
                    </div>

                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">App Development</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-6.jpg" alt="image">
                    </div>

                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Digital Agency</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-7.jpg" alt="image">
                    </div>

                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Website Development</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-6">
                <div class="single-portfolio">
                    <div class="portfolio-image">
                        <img src="assets/img/portfolio/portfolio-8.jpg" alt="image">
                    </div>

                    <div class="portfolio-content">
                        <a routerLink="/portfolio-details"><i class='bx bx-plus'></i></a>
                        <h3><a routerLink="/portfolio-details">Logo & Branding</a></h3>
                    </div>
                </div>
            </div>

            <div class="col-lg-12 col-md-12">
                <div class="pagination-area">
                    <a routerLink="/portfolio-1" class="prev page-numbers"><i class="flaticon-left-arrow"></i></a>
                    <a routerLink="/portfolio-1" class="page-numbers">1</a>
                    <span class="page-numbers current" aria-current="page">2</span>
                    <a routerLink="/portfolio-1" class="page-numbers">3</a>
                    <a routerLink="/portfolio-1" class="page-numbers">4</a>
                    <a routerLink="/portfolio-1" class="next page-numbers"><i class="flaticon-next"></i></a>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Portfolio -->