<!-- Footer -->
<div class="footer-area pt-100 pb-70">
    <div class="container">
        <div class="row">
            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget">
                    <div class="logo">
                        <a routerLink="/"><img src="assets/img/wild_sky_logo.png" alt="image"></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>
                        <div>
                            Performances
                        </div>
                    </h3>

                    <ul class="quick-links">
                        <li><a routerLink="/performances">Calendar: Birds of Prey</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Featured Services</h3>

                    <ul class="quick-links">
                        <li><a routerLink="/">About Our Live Shows</a></li>
                    </ul>
                </div>
            </div>

            <div class="col-lg-3 col-sm-6">
                <div class="single-footer-widget pl-5">
                    <h3>Information</h3>

                    <ul class="footer-contact-info">
                        <li>
                            <i class="flaticon-call"></i>
                            <span>Phone</span>
                            <a href="tel:(352) 502-2222">(352) 502-2222</a>
                        </li>
                        <li>
                            <i class="flaticon-email-1"></i>
                            <span>Email</span>
                            <a href="mailto:robswsp@gmail.com">robswsp@gmail.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Footer -->

<!-- Copyright -->
<div class="copyright-area">
    <div class="container">
        <div class="copyright-area-content">
            <div class="row align-items-center">
                <div class="col-lg-6 col-md-6">
                    <p>© 2022 Wild Sky Productions LLC</p>
                    <p>Web Site Development and Administration by <a href = "mailto: DualReality@hippogryph.net">Dual Reality</a></p>
                    <p style="font-size:small">Background image is cropped from
                        <a href="https://commons.wikimedia.org/wiki/File:Male_Peregrine_Falcon_(7172188034).jpg">
                        this photo on Wikipedia</a>. Used here by the
                        <a href="https://creativecommons.org/licenses/by/2.0/deed.en">CC Attribution License</a>.
                    </p>
                </div>
                <div class="col-lg-6 col-md-6">
                    <ul>
                        <li><a routerLink="/privacy-policy">Privacy Policy</a></li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Copyright -->

<!-- Go Top -->
<div class="go-top">
    <i class='bx bx-chevron-up'></i>
</div>
<!-- End Go Top -->