<!-- Page Banner -->
<div class="page-banner-area item-bg4">
    <div class="container">
        <div class="page-banner-content">
            <h2>Portfolio Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Portfolio Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Portfolio Details -->
<section class="portfolio-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="portfolio-details-image">
                    <img src="assets/img/portfolio-details.jpg" alt="portfolio">
                </div>

                <div class="portfolio-details-desc">
                    <h3>Competitor Analysis</h3>
                    <p>Lorem ipsum dolor sit amet, conse cte tuer adipiscing elit, sed diam no nu m nibhie eui smod. Facil isis atve eros et accumsan etiu sto odi dignis sim qui blandit praesen lup ta de er. At molestiae appellantur pro. Vis wisi oportere per ic ula ad, ei latine prop riae na, mea cu purto debitis.</p>
                    <div class="features-text">
                        <h4><i class="flaticon-check"></i> Core Development</h4>
                        <p>No fake products and services. The customer is king their lives and needs are the inspiration.</p>
                    </div>
                    <div class="features-text">
                        <h4><i class="flaticon-check"></i> Define Your Choices</h4>
                        <p>No fake products and services. The customer is king their lives and needs are the inspiration.</p>
                    </div>
                    <h3>Customer Satisfaction for a Digital Marketing Agency</h3>
                    <p>Nost rud no eos, no impedit dissenti as mea, ea vide labor amus neglegentur vix. Ancillae intellegat vix et. Sit causae laoreet nolu ise. Ad po exerci nusquam eos te. Cu altera expet enda qui, munere oblique theo phrastu ea vix. Ne nec modus civibus modera tius, sit ei lorem doctus. Ne docen di verterem reformidans eos. Cu altera expetenda qui munere oblique theophr astus ea vix modus civiu mod eratius.</p>
                    <h4>Results</h4>
                    <p>Lorem ipsum dolor sit amet, conse cte tuer adipiscing elit, sed diam no nu m nibhie eui smod. Facil isis atve eros et accumsan etiu sto odi dignis sim qui blandit praesen lup ta de er. At molestiae appellantur pro. Vis wisi oportere per ic ula ad, ei latine prop riae na, mea cu purto debitis.</p>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <div class="portfolio-details-information">
                    <ul>
                        <li>
                            <div class="icon">
                                <i class='bx bx-user'></i>
                            </div>
                            <span>Client:</span>
                            <a href="https://envytheme.com/" target="_blank">EnvyTheme.com</a>
                            <a href="#" target="_blank">ThemeForest.com</a>
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bxs-map'></i>
                            </div>
                            <span>Location:</span>
                            New York, USA
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-purchase-tag'></i>
                            </div>
                            <span>Technologies:</span>
                            IT Startups & Digital Services
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-check'></i>
                            </div>
                            <span>Completed:</span>
                            28 April 2020
                        </li>
                        <li>
                            <div class="icon">
                                <i class='bx bx-globe'></i>
                            </div>
                            <span>Website:</span>
                            <a href="https://envytheme.com/" target="_blank">EnvyTheme.com</a>
                        </li>
                    </ul>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Portfolio Details -->

<!-- Portfolio -->
<section class="portfolio-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Portfolio</span>
            <h2>More Projects You Might Lik</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-5.jpg" alt="image">
                        </a>
                    </div>

                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Creative Work</a></h3>
                        <span>Web Design</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-2.jpg" alt="image">
                        </a>
                    </div>

                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Ecommerce Work</a></h3>
                        <span>Creative Agency</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-3.jpg" alt="image">
                        </a>
                    </div>

                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">App Development</a></h3>
                        <span>IOS Android</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Portfolio -->