import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-about-live-shows',
  templateUrl: './about-live-shows.component.html',
  styleUrls: ['./about-live-shows.component.scss']
})
export class AboutLiveShowsComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
