<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>About Our Live Shows</h2>
            <ul>
                <li>
                    <a href="index.html">Home</a>
                </li>
                <li>About Our Live Shows</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Show Schedule -->
<section class="features-area pt-100 pb-70" style="padding-top:70px">
    <div class="container">
        <div style="display:table;width:100%">
            <div style="display:table-row">
                <div style="display:table-cell;width:50%">
                    <img src="assets/img/live-logo.png" alt="image">
                </div>
                <div style="display:table-cell;width:50%;vertical-align:middle;font-size:22px;">
                    <h4>Live Show</h4>
                    <p>Our LIVE show has been seen by over 6,566,000 visitors at theme parks, attractions and zoos across America.</p>
                    <p>We strive to reveal the nature of our world by highlighting conservation concerns through engaging and positively
                        entertaining performances.</p>
                </div>
            </div>
            <div style="display:table-row">
                <div style="display:table-cell;width:50%;vertical-align:middle;font-size:22px;">
                    <h4>Thrilling Encounters</h4>
                    <p>Experience a close flyby from our sky hunters.  You will gasp in awe at their beautiful, impressive and deadly
                    features right before your eyes!
                    </p>
                    <p>Our goal is to promote conservation through awareness in an entertaining format.</p>
                </div>
                <div style="display:table-cell;width:50%">
                    <img src="assets/img/free-flying.jpg" alt="image">
                </div>
            </div>
            <div style="display:table-row">
                <div style="display:table-cell;width:50%">
                    <img src="assets/img/venues.jpg" alt="image">
                </div>
                <div style="display:table-cell;width:50%;vertical-align:middle;font-size:22px;">
                    <h4>Experience</h4>
                    <p>For more than 30 years Rob Sinkler and his team have been entertaining audiences at theme parks, zoos,
                        wildlife parks, renaissance festivals, fairs and resorts.
                     </p>
                    <p>WSP specializes in live performances featuring a variety of birds from around the globe.</p>
                </div>
            </div>
        </div>
    </div>
</section>