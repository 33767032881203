<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>Blog Details</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Blog Details</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Blog Details -->
<section class="blog-details-area ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-8 col-md-12">
                <div class="blog-details-desc">
                    <div class="article-image">
                        <img src="assets/img/blog-details.jpg" alt="image">
                    </div>

                    <div class="article-content">
                        <div class="entry-meta">
                            <ul>
                                <li>
                                    <span>Posted On:</span> 
                                    <a routerLink="/">September 31, 2020</a>
                                </li>
                                <li>
                                    <span>Posted By:</span> 
                                    <a routerLink="/">John Anderson</a>
                                </li>
                            </ul>
                        </div>

                        <h3>Digital Marketing Agency Blogs You Should Read</h3>
                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                        <blockquote class="wp-block-quote">
                            <p>It is a long established fact that a reader will be distracted by the readable content of a page when looking at its layout.</p>
                        </blockquote>

                        <p>Quuntur magni dolores eos qui ratione voluptatem sequi nesciunt. Neque porro quia non numquam eius modi tempora incidunt ut labore et dolore magnam dolor sit amet, consectetur adipisicing.</p>

                        <ul class="wp-block-gallery columns-3">
                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/blog-4.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/blog-5.jpg" alt="image">
                                </figure>
                            </li>

                            <li class="blocks-gallery-item">
                                <figure>
                                    <img src="assets/img/blog/blog-6.jpg" alt="image">
                                </figure>
                            </li>
                        </ul>

                        <h3>Four major elements that we offer:</h3>

                        <ul class="features-list">
                            <li><i class="flaticon-check"></i> Scientific Skills For getting a better result</li>
                            <li><i class="flaticon-check"></i> Communication Skills to getting in touch</li>
                            <li><i class="flaticon-check"></i> A Career Overview opportunity Available</li>
                            <li><i class="flaticon-check"></i> A good Work Environment For work</li>
                        </ul>

                        <h3>It’s Time To Think Differently About Homeschooling</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in  sed quia non numquam eius modi tempora incidunt ut labore et dolore magnam aliquam quaerat voluptatem.</p>

                        <h3>The Rise Of Marketing And Why You Need It</h3>
                        <p>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud.</p>
                    </div>

                    <div class="article-footer">
                        <div class="article-tags">
                            <span><i class='bx bxs-bookmark'></i></span>
                            <a routerLink="/blog-1">Business</a>,
                            <a routerLink="/blog-1">IT Startups</a>
                        </div>

                        <div class="article-share">
                            <ul class="social">
                                <li><span>Share:</span></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                                <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            </ul>
                        </div>
                    </div>

                    <div class="post-navigation">
                        <div class="navigation-links">
                            <div class="nav-previous">
                                <a routerLink="/blog-details"><i class="flaticon-left-arrow"></i> Prev Post</a>
                            </div>

                            <div class="nav-next">
                                <a routerLink="/blog-details">Next Post <i class="flaticon-next"></i></a>
                            </div>
                        </div>
                    </div>

                    <div class="comments-area">
                        <h3 class="comments-title">3 Comments:</h3>

                        <ol class="comment-list">
                            <li class="comment">
                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/client/client-1.jpg" class="avatar" alt="image">
                                            <b class="fn">John Jones</b>
                                        </div>
                                        <div class="comment-metadata">
                                            <a routerLink="/">
                                                <time>April 24, 2020 at 10:59 am</time>
                                            </a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>
                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>

                                <ol class="children">
                                    <li class="comment">
                                        <article class="comment-body">
                                            <footer class="comment-meta">
                                                <div class="comment-author vcard">
                                                    <img src="assets/img/client/client-2.jpg" class="avatar" alt="image">
                                                    <b class="fn">Steven Smith</b>
                                                </div>
                                                <div class="comment-metadata">
                                                    <a routerLink="/">
                                                        <time>April 24, 2020 at 10:59 am</time>
                                                    </a>
                                                </div>
                                            </footer>
                                            <div class="comment-content">
                                                <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                            </div>
                                            <div class="reply">
                                                <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                            </div>
                                        </article>
                                    </li>
                                </ol>

                                <article class="comment-body">
                                    <footer class="comment-meta">
                                        <div class="comment-author vcard">
                                            <img src="assets/img/client/client-3.jpg" class="avatar" alt="image">
                                            <b class="fn">Sarah Taylor</b>
                                        </div>
                                        <div class="comment-metadata">
                                            <a routerLink="/">
                                                <time>April 24, 2020 at 10:59 am</time>
                                            </a>
                                        </div>
                                    </footer>
                                    <div class="comment-content">
                                        <p>Lorem Ipsum has been the industry’s standard dummy text ever since the 1500s, when an unknown printer took a galley of type and scrambled it to make a type specimen.</p>
                                    </div>
                                    <div class="reply">
                                        <a routerLink="/blog-details" class="comment-reply-link">Reply</a>
                                    </div>
                                </article>
                            </li>
                        </ol>

                        <div class="comment-respond">
                            <h3 class="comment-reply-title">Leave a Reply</h3>

                            <form class="comment-form">
                                <p class="comment-notes">
                                    <span id="email-notes">Your email address will not be published.</span>
                                    Required fields are marked 
                                    <span class="required">*</span>
                                </p>
                                <p class="comment-form-author">
                                    <label>Name <span class="required">*</span></label>
                                    <input type="text" id="author" placeholder="Your Name*" name="author" required="required">
                                </p>
                                <p class="comment-form-email">
                                    <label>Email <span class="required">*</span></label>
                                    <input type="email" id="email" placeholder="Your Email*" name="email" required="required">
                                </p>
                                <p class="comment-form-url">
                                    <label>Website</label>
                                    <input type="url" id="url" placeholder="Website" name="url">
                                </p>
                                <p class="comment-form-comment">
                                    <label>Comment</label>
                                    <textarea name="comment" id="comment" cols="45" placeholder="Your Comment..." rows="5" maxlength="65525" required="required"></textarea>
                                </p>
                                <p class="comment-form-cookies-consent">
                                    <input type="checkbox" value="yes" name="wp-comment-cookies-consent" id="wp-comment-cookies-consent">
                                    <label for="wp-comment-cookies-consent">Save my name, email, and website in this browser for the next time I comment.</label>
                                </p>
                                <p class="form-submit">
                                    <input type="submit" name="submit" id="submit" class="submit" value="Post A Comment">
                                </p>
                            </form>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-12">
                <aside class="widget-area">
                    <section class="widget widget_search">
                        <h3 class="widget-title">Search</h3>

                        <form class="search-form">
                            <label>
                                <span class="screen-reader-text">Search for:</span>
                                <input type="search" class="search-field" placeholder="Search...">
                            </label>
                            <button type="submit"><i class="flaticon-loupe"></i></button>
                        </form>
                    </section>

                    <section class="widget widget_rainz_posts_thumb">
                        <h3 class="widget-title">Popular Posts</h3>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg1" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 10, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">The Data Surrounding Higher Education</a></h4>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg2" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 21, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">Conversion Rate the Sales Funnel Optimization</a></h4>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg3" role="img"></span>
                            </a>
                            <div class="info">
                                <span>June 30, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">Business Data is changing the world’s Energy</a></h4>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg4" role="img"></span>
                            </a>
                            <div class="info">
                                <span>May 10, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">The Billionaire Guide On Design That Will Get You Rich</a></h4>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg5" role="img"></span>
                            </a>
                            <div class="info">
                                <span>May 21, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">The Data-Driven Approach To Understanding Your Users</a></h4>
                            </div>
                        </article>

                        <article class="item">
                            <a routerLink="/blog-details" class="thumb">
                                <span class="fullimage cover bg6" role="img"></span>
                            </a>
                            <div class="info">
                                <span>May 30, 2020</span>
                                <h4 class="title usmall"><a routerLink="/blog-details">7 Great Tips For Earn More Money From Digital Industry</a></h4>
                            </div>
                        </article>
                    </section>

                    <section class="widget widget_categories">
                        <h3 class="widget-title">Categories</h3>

                        <ul>
                            <li><a routerLink="/blog-1">Creative Web Design <span class="post-count">(03)</span></a></li>
                            <li><a routerLink="/blog-1">Digital Agency <span class="post-count">(05)</span></a></li>
                            <li><a routerLink="/blog-1">Software Engineers <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog-1">Marketing Agency <span class="post-count">(04)</span></a></li>
                            <li><a routerLink="/blog-1">Data Analysis <span class="post-count">(08)</span></a></li>
                            <li><a routerLink="/blog-1">App Development <span class="post-count">(10)</span></a></li>
                            <li><a routerLink="/blog-1">Web Application <span class="post-count">(14)</span></a></li>
                            <li><a routerLink="/blog-1">Logo & Branding <span class="post-count">(18)</span></a></li>
                            <li><a routerLink="/blog-1">IT Startups <span class="post-count">(20)</span></a></li>
                            <li><a routerLink="/blog-1">Digital Services <span class="post-count">(25)</span></a></li>
                        </ul>
                    </section>

                    <section class="widget widget_tag_cloud">
                        <h3 class="widget-title">Popular Tags</h3>

                        <div class="tagcloud">
                            <a routerLink="/blog-1">Business <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-1">Design <span class="tag-link-count"> (3)</span></a>
                            <a routerLink="/blog-1">Braike <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-1">Fashion <span class="tag-link-count"> (2)</span></a>
                            <a routerLink="/blog-1">Travel <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Smart <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Marketing <span class="tag-link-count"> (1)</span></a>
                            <a routerLink="/blog-1">Tips <span class="tag-link-count"> (2)</span></a>
                        </div>
                    </section>
                </aside>
            </div>
        </div>
    </div>
</section>
<!-- End Blog Details -->