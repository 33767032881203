<!-- Main Banner -->
<div class="main-banner">
    <div class="main-banner-item item-two">
        <div class="d-table">
            <div class="d-table-cell">
                <div class="container-fluid">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-content">
                                <h1>We Provide Digital Marketing Solutions</h1>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                                <div class="banner-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="main-banner-image">
                                <img src="assets/img/banner/banner-image-2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="main-banner-shape">
        <div class="shape-4"><img src="assets/img/banner/banner-shape-2.png" alt="image"></div>
        <div class="shape-5"><img src="assets/img/banner/banner-shape-2.png" alt="image"></div>
        <div class="shape-6"><img src="assets/img/banner/banner-shape-3.png" alt="image"></div>
        <div class="shape-7"><img src="assets/img/banner/banner-shape-4.png" alt="image"></div>
        <div class="shape-8"><img src="assets/img/banner/banner-shape-5.png" alt="image"></div>
    </div>
</div>
<!-- End Main Banner -->

<!-- About -->
<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image-warp"></div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content warp">
                    <span>About Us</span>
                    <h3>We Help Companies to Generate Leads and Increase in Sales</h3>
                    <div class="bar"></div>
                    <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore  dolore magna aliqua.</strong>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>
                    <div class="about-btn">
                        <a routerLink="/about-1" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>   
<!-- End About -->

<!-- Features -->
<section class="features-area pb-100">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-6 col-md-12">
                <div class="features-inner-content">
                    <span>Features</span>
                    <h3>Digital Marketing</h3>
                    <div class="bar"></div>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra.</p>
                    <ul class="features-list">
                        <li><span><i class="flaticon-checked"></i> SEO Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Social Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Email Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Facebook Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Youtube Marketing</span></li>
                        <li><span><i class="flaticon-checked"></i> Page Ranking</span></li>
                    </ul>
                    <div class="features-btn">
                        <a routerLink="/" class="default-btn">Learn More</a>
                    </div>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="features-image">
                    <img src="assets/img/features/features-1.png" alt="image">
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features -->

<!-- Services -->
<section class="services-area bg-fafafa pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Services we provided</span>
            <h2>Our Digital Marketing Services</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/services/services-7.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Creative Web Design</a></h3>
                        <span>Web Design</span>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/services/services-8.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Digital Agency</a></h3>
                        <span>Agency</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/services/services-9.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Complex Dashboard</a></h3>
                        <span>Solutions</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/services/services-10.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Software Engineers</a></h3>
                        <span>Analysis</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/services/services-11.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Marketing Agency</a></h3>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-services-item">
                    <div class="image">
                        <a routerLink="/services-details"><img src="assets/img/services/services-12.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <h3><a routerLink="/services-details">Data Analysis</a></h3>
                        <span>Explanation</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Services -->

<!-- Portfolio -->
<section class="portfolio-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Portfolio Showcase</span>
            <h2>Our Impressive Portfolio</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-1.jpg" alt="image">
                        </a>
                    </div>
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Creative Work</a></h3>
                        <span>Web Design</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-2.jpg" alt="image">
                        </a>
                    </div>
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Ecommerce Work</a></h3>
                        <span>Creative Agency</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-3.jpg" alt="image">
                        </a>
                    </div>
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">App Development</a></h3>
                        <span>IOS Android</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-4.jpg" alt="image">
                        </a>
                    </div>
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Dashboard App</a></h3>
                        <span>Web Development</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-5.jpg" alt="image">
                        </a>
                    </div>
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Web Application</a></h3>
                        <span>Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-portfolio-item">
                    <div class="portfolio-image">
                        <a routerLink="/portfolio-details">
                            <img src="assets/img/portfolio/portfolio-6.jpg" alt="image">
                        </a>
                    </div>
                    <div class="portfolio-content">
                        <h3><a routerLink="/portfolio-details">Logo & Branding</a></h3>
                        <span>Agency</span>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Portfolio -->

<!-- Team -->
<section class="team-area pb-100">
    <div class="container-fluid">
        <div class="section-title">
            <span>Team Member</span>
            <h2>Our Expert Team</h2>
            <div class="bar"></div>
        </div>
        
        <div class="team-slider owl-carousel owl-theme">
            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-1.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Justin Roberto</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-2.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Jacinda Meri</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-3.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Miraj Alex</h3>
                        <span>Chief Executive Officer</span>
                    </div>
                </div>
            </div>

            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-4.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Edward Bold</h3>
                        <span>UI/UX Designer</span>
                    </div>
                </div>
            </div>

            <div class="single-team">
                <div class="image">
                    <img src="assets/img/team/team-5.jpg" alt="image">

                    <ul class="social">
                        <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                    </ul>

                    <div class="content">
                        <h3>Alastair Cook</h3>
                        <span>Marketing Support</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team -->

<!-- Pricing -->
<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plans</span>
            <h2>The Best Solutions for Our Clients</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box top-1">
                    <div class="pricing-header">
                        <h3>Basic Plan</h3>
                    </div>

                    <div class="price">
                        $24
                    </div>

                    <ul class="pricing-features">
                        <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                        <li><i class="flaticon-check-mark"></i> SEO Management</li>
                        <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                        <li><i class="flaticon-check-mark"></i> Link Building</li>
                        <li><i class="flaticon-check-mark"></i> Site Migration</li>
                        <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                        <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                    </ul>

                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>

                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box">
                    <div class="pricing-header">
                        <h3>Standard Plan</h3>
                    </div>

                    <div class="price">
                        $59
                    </div>

                    <ul class="pricing-features">
                        <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                        <li><i class="flaticon-check-mark"></i> SEO Management</li>
                        <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                        <li><i class="flaticon-check-mark"></i> Link Building</li>
                        <li><i class="flaticon-check-mark"></i> Site Migration</li>
                        <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                        <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                    </ul>

                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>

                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-pricing-box top-2">
                    <div class="pricing-header">
                        <h3>Premium Plan</h3>
                    </div>

                    <div class="price">
                        $89
                    </div>

                    <ul class="pricing-features">
                        <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                        <li><i class="flaticon-check-mark"></i> SEO Management</li>
                        <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                        <li><i class="flaticon-check-mark"></i> Link Building</li>
                        <li><i class="flaticon-check-mark"></i> Site Migration</li>
                        <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                        <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                    </ul>

                    <div class="pricing-btn">
                        <a routerLink="/" class="default-btn">Get Started</a>
                    </div>

                    <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->

<!-- Testimonials -->
<section class="testimonial-area testimonial-two pb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 offset-lg-6">
                <div class="testimonial-content">
                    <span>Testimonials</span>
                    <h2>Our Client’s Review</h2>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Morris Jacket</h4>
                                <span>Web Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-two"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Mahindra jhon</h4>
                                <span>App Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-three"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Lee Munroe</h4>
                                <span>Web Designer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials -->

<!-- Blog -->
<section class="blog-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Blog</span>
            <h2>Latest News From Blog</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-1.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>20 March, 2020</span>
                        <h3><a routerLink="/blog-details">7 Great Tips For Earn More Money From Digital Industry</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-2.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>25 March, 2020</span>
                        <h3><a routerLink="/blog-details">How To Boost Your Digital Marketing Agency</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>

            <div class="col-lg-4 col-md-6">
                <div class="single-blog">
                    <div class="image">
                        <a routerLink="/blog-details"><img src="assets/img/blog/blog-3.jpg" alt="image"></a>
                    </div>
                    <div class="content">
                        <span>28 March, 2020</span>
                        <h3><a routerLink="/blog-details">The Billionaire Guide On Design That will Get You Rich</a></h3>
                        <a routerLink="/blog-details" class="blog-btn">Read More <i class='bx bx-chevrons-right'></i></a>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <div class="default-shape">
        <div class="shape1"><img src="assets/img/default-shape/default-shape1.png" alt="image"></div>
        <div class="shape2"><img src="assets/img/default-shape/default-shape2.png" alt="image"></div>
        <div class="shape3"><img src="assets/img/default-shape/default-shape3.png" alt="image"></div>
        <div class="shape4"><img src="assets/img/default-shape/default-shape4.png" alt="image"></div>
    </div>
</section>
<!-- End Blog -->

<!-- Partner -->
<div class="partner-area pb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/partner-1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-5.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-6.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Partner -->