<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>Contact Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Contact Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->


<!-- Contact Info -->
<section class="contact-area ptb-100">
    <div class="container">
        <div class="general-contact-info">
            <div class="contact-info-content">
                <h3>Contact us by Phone or Email</h3>
                <h2>
                    <i class="flaticon-call"></i>&nbsp;<a href="tel:(352) 502-2222">(352) 502-2222</a>
                    <span>OR</span>
                    <i class="flaticon-email-1"></i>&nbsp;<a href="mailto:robswsp@gmail.com">robswsp@gmail.com</a>
                </h2>
            </div>
        </div>
    </div>
</section>
<!-- End Contact Info -->

