<!-- Page Banner -->
<div class="page-banner-area item-bg3">
    <div class="container">
        <div class="page-banner-content">
            <h2>Pricing</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Pricing</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Pricing -->
<section class="pricing-area pt-100 pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plans</span>
            <h2>The Best Solutions for Our Clients</h2>
            <div class="bar"></div>
        </div>

        <div class="tab pricing-tab">
            <ul class="tabs">
                <li><a href="#">Monthly</a></li>
                <li><a href="#">Yearly</a></li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-1">
                                <div class="pricing-header">
                                    <h3>Basic Plan</h3>
                                </div>
    
                                <div class="price">
                                    $24
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Standard Plan</h3>
                                </div>
    
                                <div class="price">
                                    $59
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-2">
                                <div class="pricing-header">
                                    <h3>Premium Plan</h3>
                                </div>
    
                                <div class="price">
                                    $89
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-1">
                                <div class="pricing-header">
                                    <h3>Basic Plan</h3>
                                </div>
    
                                <div class="price">
                                    $224
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Standard Plan</h3>
                                </div>
    
                                <div class="price">
                                    $259
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-2">
                                <div class="pricing-header">
                                    <h3>Premium Plan</h3>
                                </div>
    
                                <div class="price">
                                    $289
                                </div>
    
                                <ul class="pricing-features">
                                    <li><i class="flaticon-check-mark"></i> SEO Audits</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Management</li>
                                    <li><i class="flaticon-check-mark"></i> SEO Copywriting</li>
                                    <li><i class="flaticon-check-mark"></i> Link Building</li>
                                    <li><i class="flaticon-check-mark"></i> Site Migration</li>
                                    <li><i class="flaticon-check-mark"></i> Video Camplaigns</li>
                                    <li><i class="flaticon-check-mark"></i> Unlimited SEO Keywords</li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a routerLink="/" class="default-btn">Get Started</a>
                                </div>
    
                                <div class="pricing-shape"><img src="assets/img/pricing-shape.png" alt="image"></div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->