import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-portfolio-two-column',
  templateUrl: './portfolio-two-column.component.html',
  styleUrls: ['./portfolio-two-column.component.scss']
})
export class PortfolioTwoColumnComponent implements OnInit {

  constructor() { }

  ngOnInit(): void {
  }

}
