<!-- Page Banner -->
<div class="page-banner-area item-bg2">
    <div class="container">
        <div class="page-banner-content">
            <h2>Testimonials</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Testimonials</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Testimonials -->
<section class="testimonial-area testimonial-two ptb-100">
    <div class="container">
        <div class="row">
            <div class="col-lg-6 col-md-12 offset-lg-6">
                <div class="testimonial-content">
                    <span>Testimonials</span>
                    <h2>Our Client’s Review</h2>
                    <div class="bar"></div>
                </div>
            </div>
        </div>

        <div class="testimonial-slider owl-carousel owl-theme">
            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Morris Jacket</h4>
                                <span>Web Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-two"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Mahindra jhon</h4>
                                <span>App Developer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>

            <div class="testimonial-item">
                <div class="row">
                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-image image-three"></div>
                    </div>

                    <div class="col-lg-6 col-md-12">
                        <div class="testimonial-inner-content">
                            <div class="icon">
                                <i class="flaticon-left-quotes-sign"></i>
                            </div>
                            <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida Risus  maecenas accumsan noniice Lorem Ipsum.</p>
                            <div class="info-text">
                                <h4>Lee Munroe</h4>
                                <span>Web Designer</span>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Testimonials -->