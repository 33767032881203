<!-- Page Banner -->
<div class="page-banner-area item-bg4">
    <div class="container">
        <div class="page-banner-content">
            <h2>About Us</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>About Us</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- About -->
<section class="about-area ptb-100">
    <div class="container-fluid">
        <div class="row">
            <div class="col-lg-6 col-md-12">
                <div class="about-image-warp image-three">
                    <a href="https://www.youtube.com/watch?v=ODfy2YIKS1M" class="video-btn popup-youtube">
                        <i class='bx bx-play'></i>
                    </a>
                </div>
            </div>

            <div class="col-lg-6 col-md-12">
                <div class="about-content warp">
                    <span>About Us</span>
                    <h3>We Help Companies to Generate Leads and Increase in Sales</h3>
                    <div class="bar"></div>
                    <strong>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt labore  dolore magna aliqua.</strong>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.</p>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Online Presence</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Marketing Strategy</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>

                    <div class="about-inner-content">
                        <div class="icon">
                            <i class="flaticon-check"></i>
                        </div>
                        <h4>Promote local Sale</h4>
                        <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>   
<!-- End About -->

<!-- Team -->
<section class="team-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Team Member</span>
            <h2>Our Expert Team</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-6.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Alex Maxwel</h3>
                        <span>CEO & Founder</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-7.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Justin Roberto</h3>
                        <span>Head Of Marketing</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-8.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Louis Agassiz</h3>
                        <span>Web Developer</span>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team-box">
                    <div class="image">
                        <img src="assets/img/team/team-9.jpg" alt="image">

                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                        </ul>
                    </div>

                    <div class="content">
                        <h3>Carl Anderson</h3>
                        <span>Android/IOS Developer</span>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team -->

<!-- Pricing -->
<section class="pricing-area pb-70">
    <div class="container">
        <div class="section-title">
            <span>Pricing Plans</span>
            <h2>The Best Solutions for Our Clients</h2>
            <div class="bar"></div>
        </div>

        <div class="tab pricing-tab">
            <ul class="tabs">
                <li>
                    <a href="#">Monthly</a>
                </li>
                <li>
                    <a href="#">Yearly</a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-1">
                                <div class="pricing-header">
                                    <h3>Basic Plan</h3>
                                </div>
    
                                <div class="price">
                                    $24
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Standard Plan</h3>
                                </div>
    
                                <div class="price">
                                    $59
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-2">
                                <div class="pricing-header">
                                    <h3>Premium Plan</h3>
                                </div>
    
                                <div class="price">
                                    $89
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row">
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-1">
                                <div class="pricing-header">
                                    <h3>Basic Plan</h3>
                                </div>
    
                                <div class="price">
                                    $224
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box">
                                <div class="pricing-header">
                                    <h3>Standard Plan</h3>
                                </div>
    
                                <div class="price">
                                    $259
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
    
                        <div class="col-lg-4 col-md-6">
                            <div class="single-pricing-box top-2">
                                <div class="pricing-header">
                                    <h3>Premium Plan</h3>
                                </div>
    
                                <div class="price">
                                    $289
                                </div>
    
                                <ul class="pricing-features">
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Audits
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Management
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        SEO Copywriting
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Link Building
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Site Migration
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Video Camplaigns
                                    </li>
                                    <li>
                                        <i class="flaticon-check-mark"></i>
                                        Unlimited SEO Keywords
                                    </li>
                                </ul>
    
                                <div class="pricing-btn">
                                    <a href="#" class="default-btn">
                                        Get Started
                                    </a>
                                </div>
    
                                <div class="pricing-shape">
                                    <img src="assets/img/pricing-shape.png" alt="image">
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Pricing -->

<!-- Client -->
<section class="client-area ptb-100">
    <div class="container">
        <div class="section-title">
            <span>Testimonials</span>
            <h2>Client’s Review</h2>
            <div class="bar"></div>
        </div>

        <div class="client-slider owl-carousel owl-theme">
            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-1.jpg" alt="image">
                    <h3>Jacinda Meri</h3>
                    <span>CEO & Founder</span>
                </div>
            </div>

            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-2.jpg" alt="image">
                    <h3>Miraj Alex</h3>
                    <span>Chief Executive Officer</span>
                </div>
            </div>

            <div class="client-item">
                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan lacus vel facilisis.Lorem Ipsum is simply dummy text of the printing and typesetting industry. Lorem Ipsum has been the industry standard dummy text ever since the 1500s, when an unknown printer took a galley</p>
                <div class="client-info">
                    <img src="assets/img/client/client-3.jpg" alt="image">
                    <h3>Edward Bold</h3>
                    <span>Web Developer</span>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Client -->

<!-- FunFacts -->
<section class="fun-facts-area pt-100 pb-70">
    <div class="container">
        <div class="row align-items-center">
            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-checked"></i>
                    </div>
                    <h3><span class="odometer" data-count="950">00</span></h3>
                    <p>Completed Project</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-happy"></i>
                    </div>
                    <h3><span class="odometer" data-count="850">00</span></h3>
                    <p>Happy Clients</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-technical-support"></i>
                    </div>
                    <h3><span class="odometer" data-count="550">00</span></h3>
                    <p>Multi Service</p>
                </div>
            </div>

            <div class="col-lg-3 col-md-6 col-sm-6">
                <div class="single-fun-fact-box">
                    <div class="icon">
                        <i class="flaticon-trophy"></i>
                    </div>
                    <h3><span class="odometer" data-count="750">00</span></h3>
                    <p>Winning Awards</p>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End FunFacts -->

<!-- Subscribe -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>
<!-- End Subscribe -->

<!-- Partner -->
<div class="partner-area ptb-100">
    <div class="container">
        <div class="partner-slider owl-carousel owl-theme">
            <div class="partner-item">
                <img src="assets/img/partner/partner-1.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-2.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-3.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-4.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-5.png" alt="image">
            </div>

            <div class="partner-item">
                <img src="assets/img/partner/partner-6.png" alt="image">
            </div>
        </div>
    </div>
</div>
<!-- End Partner -->