<!-- Navbar -->
<div class="navbar-area">
    <div class="main-responsive-nav">
        <div class="container">
            <div class="main-responsive-menu">
                <div class="logo">
                    <a routerLink="/"><img src="assets/img/wild_sky_logo.png" alt="image"></a>
                </div>
            </div>
        </div>
    </div>

    <div class="main-navbar">
        <div class="container">
            <nav class="navbar navbar-expand-md navbar-light">
                <a class="navbar-brand" routerLink="/"><img src="assets/img/wild_sky_logo.png" alt="image"></a>

                <div class="collapse navbar-collapse mean-menu" id="navbarSupportedContent">
                    <ul class="navbar-nav">
                        <li class="nav-item">
                            <a routerLink="/" class="nav-link">Home</a>
                        </li>

                        <li class="nav-item">
                            <div style="text-align:center">
                                <a routerLink="/performances" class="nav-link">
                                    Calendar: Birds of Prey<br>Performances
                                </a>
                            </div>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">Services <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/about-live-shows" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">About Our Live Shows</a></li>
                            </ul>
                        </li>

                        <li class="nav-item">
                            <a href="javascript:void(0)" class="nav-link">About Us <i class='bx bx-chevron-down'></i></a>

                            <ul class="dropdown-menu">
                                <li class="nav-item"><a routerLink="/contact-2" class="nav-link" routerLinkActive="active"[routerLinkActiveOptions]="{exact: true}">Contact Us</a></li>
                            </ul>
                        </li>
                    </ul>

                    <div class="others-options d-flex align-items-center">
                        <div class="option-item">
                            <div>Visit us on Facebook: <a href="https://www.facebook.com/wildskyproductions" target="_blank"><img src="assets/img/facebook.png" alt="image"></a></div>
                        </div>
                    </div>
                </div>
            </nav>
        </div>
    </div>

    <div class="others-option-for-responsive">
        <div class="container">
            <div class="dot-menu">
                <div class="inner">
                    <div class="circle circle-one"></div>
                    <div class="circle circle-two"></div>
                    <div class="circle circle-three"></div>
                </div>
            </div>
            
            <div class="container">
                <div class="option-inner">
                    <div class="others-options d-flex align-items-center">
                        <div class="option-item">
                            <div>Visit us on Facebook: <a href="https://www.facebook.com/wildskyproductions" target="_blank"><img src="assets/img/facebook.png" alt="image"></a></div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- End Navbar -->