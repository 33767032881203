<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="page-banner-content">
            <h2>Features</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Features</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Features -->
<section class="features-area pt-100 pb-70">
    <div class="container">
        <div class="section-title text-width">
            <span>Care Features</span>
            <h2>Create Awesome Service With Our Tools</h2>
            <div class="bar"></div>
        </div>

        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-promotion"></i>
                    </div>
                    <h3><a routerLink="/">Marketing Analysis</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>1</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-speed"></i>
                    </div>
                    <h3><a routerLink="/">Website Optimization</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>2</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-email"></i>
                    </div>
                    <h3><a routerLink="/">Email Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>3</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-features">
                    <div class="icon">
                        <i class="flaticon-network"></i>
                    </div>
                    <h3><a routerLink="/">Social Media Marketing</a></h3>
                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor</p>
                    <div class="number">
                        <span>4</span>
                    </div>
                    <div class="shape"><img src="assets/img/features-shape/features-shape-1.png" alt="image"></div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Features -->

<!-- Digital Experience -->
<section class="digital-experience-area pb-100">
    <div class="container">
        <div class="section-title">
            <span>Digital Experience</span>
            <h2>Outstanding Digital Experience</h2>
            <div class="bar"></div>
        </div>

        <div class="tab digital-experience-tab">
            <ul class="tabs">
                <li>
                    <a href="#">
                        <span>
                            <i class="flaticon-analysis"></i>
                            Real-Time Analytics
                        </span>
                    </a>
                </li>
                <li class="bg-5cbd12">
                    <a href="#">
                        <span>
                            <i class="flaticon-profit"></i>
                            Pay-Per-Click
                        </span>
                    </a>
                </li>
                <li class="bg-e2851b">
                    <a href="#">
                        <span>
                            <i class="flaticon-digital-marketing"></i>
                            Online Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-04b893">
                    <a href="#">
                        <span>
                            <i class="flaticon-email-marketing"></i>
                            Email Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-785eda">
                    <a href="#">
                        <span>
                            <i class="flaticon-network"></i>
                            Social Marketing
                        </span>
                    </a>
                </li>
                <li class="bg-d0465a">
                    <a href="#">
                        <span>
                            <i class="flaticon-digital-marketing-2"></i>
                            Digital Marketing
                        </span>
                    </a>
                </li>
            </ul>

            <div class="tab_content">
                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Real-Time Analytics</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-1.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Pay-Per-Click</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-2.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Online Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-3.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Email Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-4.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Social Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-5.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>

                <div class="tabs_item">
                    <div class="row align-items-center">
                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-content">
                                <h3>Digital Marketing</h3>
                                <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Quis ipsum suspendisse ultrices gravida. Risus commodo viverra maecenas accumsan facilisis.</p>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Super Responsive</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>High Security</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>

                                <div class="experience-inner-content">
                                    <div class="icon">
                                        <i class="flaticon-check"></i>
                                    </div>
                                    <h3>Optimal Choice</h3>
                                    <p>Lorem ipsum dolor sit amet, consectetur adipiscing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.</p>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-6 col-md-12">
                            <div class="digital-experience-image">
                                <img src="assets/img/digital-experience/digital-experience-6.png" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Digital Experience -->

<!-- Subscribe -->
<section class="subscribe-area ptb-100">
    <div class="container">
        <div class="subscribe-content">
            <span>Get Started Instantly!</span>
            <h2>Get Only New Update from this Newsletter</h2>
            <form class="newsletter-form">
                <input type="email" class="input-newsletter" placeholder="Enter your email" name="EMAIL">
                <button type="submit">Subscribe</button>
            </form>
        </div>
    </div>
</section>
<!-- End Subscribe -->