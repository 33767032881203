<!-- Page Banner -->
<div class="page-banner-area">
    <div class="container">
        <div class="page-banner-content">
            <h2>Team One</h2>
            <ul>
                <li><a routerLink="/">Home</a></li>
                <li>Team One</li>
            </ul>
        </div>
    </div>
</div>
<!-- End Page Banner -->

<!-- Team -->
<section class="team-area pt-100 pb-70">
    <div class="container-fluid">
        <div class="section-title">
            <span>Team Member</span>
            <h2>Our Expert Team</h2>
            <div class="bar"></div>
        </div>
        
        <div class="row">
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team-1.jpg" alt="image">
                    
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>

                        <div class="content">
                            <h3>Justin Roberto</h3>
                            <span>Head Of Marketing</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team-2.jpg" alt="image">
                    
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>

                        <div class="content">
                            <h3>Jacinda Meri</h3>
                            <span>Web Developer</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team-3.jpg" alt="image">
                    
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>

                        <div class="content">
                            <h3>Miraj Alex</h3>
                            <span>Chief Executive Officer</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team-4.jpg" alt="image">
                    
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>

                        <div class="content">
                            <h3>Edward Bold</h3>
                            <span>UI/UX Designer</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team-5.jpg" alt="image">
                    
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>

                        <div class="content">
                            <h3>Alastair Cook</h3>
                            <span>Marketing Support</span>
                        </div>
                    </div>
                </div>
            </div>

            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team-6.jpg" alt="image">
                    
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>

                        <div class="content">
                            <h3>Alex Maxwel</h3>
                            <span>CEO & Founder</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team-7.jpg" alt="image">
                    
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>

                        <div class="content">
                            <h3>Louis Agassiz</h3>
                            <span>Web Developer</span>
                        </div>
                    </div>
                </div>
            </div>
            
            <div class="col-lg-3 col-md-6">
                <div class="single-team">
                    <div class="image">
                        <img src="assets/img/team/team-8.jpg" alt="image">
                    
                        <ul class="social">
                            <li><a href="#" target="_blank"><i class='bx bxl-facebook'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-twitter'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-instagram'></i></a></li>
                            <li><a href="#" target="_blank"><i class='bx bxl-linkedin'></i></a></li>
                        </ul>

                        <div class="content">
                            <h3>Carl Anderson</h3>
                            <span>Android/IOS Developer</span>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>
<!-- End Team -->